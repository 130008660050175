module.exports = {
    // Asset Ids
    EXCLUSIVE_EXPERIENCES_TOOLTIP_CID: 'loyalty-dashboard-exclusive-experiences-tool-tip',
    REWARDS_TOOLTIP_CID: 'loyalty-dashboard-rewards-tool-tip',
    OFFERS_TOOLTIP_CID: 'loyalty-dashboard-offers-tool-tip',
    BENEFITS_TOOLTIP_CID: 'loyalty-dashboard-benefits-tool-tip',
    TELL_A_FRIEND_TOOLTIP_CID: 'loyalty-dashboard-tell-a-friend-tool-tip',
    STYLE_QUIZ_TOOLTIP_CID: 'loyalty-dashboard-style-quiz-tool-tip',
    MEMBERSHIP_FAQ_TOOLTIP_CID: 'loyalty-dashboard-faq-tool-tip',

    // Selectors
    TOOL_TIP_CONTENT: '.tool-tip-content',
    LOYALTY_TOOLTIP_ICON: '.loyalty-tooltip-icon',
    ANALYTIC_EVENT_CLASS: '.analytic-event-class',
    BENEFIT_MODAL_CLOSE_ICON: '.benefit-detail-modal .close',
    BENEFIT_DETAIL_MODAL: '.benefit-detail-dialog',
    BENEFIT_SWATCH_THUMBNAIL_MODAL: '.img-wallpaper',
    BENEFIT_SWATCH_TITLE: '.title-text',
    DOWNLOAD_SWATCH_CTA: '.download-cta',
    GO_TO_ACCOUNT_CTA: '.go-to-my-account-btn',
    BENEFIT_SWATCH_TAB: '.tab-pane.active',
    REFER_FRIEND_CTA: '.loyalty-referral-drawer-link',
    REFER_FRIEND_CTA_LINK: '.referral-cta-link',
    REFER_FRIEND_TEXT_FIELD: '.referral-link',
    STYLE_QUIZ_PENDING_QUIZ: '.finished-quiz',
    MEMBERSHIP_LOYALTY_EXPAND_COLLAPSE_CTA: '.membership-loyalty-expand-collapse',
    MEMBERSHIP_LOYALTY_EXPAND_COLLAPSE_ALL_CTA: '.membership-loyalty-expand-all',
    MEMBERSHIP_FAQ_TERMS_AND_CONDITION_LINK: '.loyalty-terms-and-conditions .faq-link',
    EXCLUSIVE_ARROW_RIGHT: '.exclusive-carousel .chevron-right',
    EXCLUSIVE_ARROW_LEFT: '.exclusive-carousel .chevron-left',
    EXCLUSIVE_PIP: '.exclusive-carousel .pip-content',
    ARROW_CLICKS: 'arrow clicks',
    EXCLUSIVE_PIP_ACTION: 'pip',
    MODULE_CHANGE: 'module change',
    ARROW_NEXT: 'next',
    ARROW_PREVIOUS: 'previous',
    EXCLUSIVE_VIEWALL: '.exclusive-dashboard-section .view-all-label',
    EXCLUSIVE_EXPERIENCE_CTA: '.cta-styling',
    EXCLUSIVE_RIGHT_SECTION: '.exclusive-right-section',
    EXCLUSIVE_CONTENT_SLOTS_CLICK: 'content slots click',
    EXCLUSIVE_SELECT_CONTENT: 'select_content',
    EXCLUSIVE_EXPERIENCE_NAME: 'exclusive experience name',
    EXCLUSIVE_CLICK_TO_VIEWALL: 'click to view all',
    EXCLUSIVE_VIEWALL_EXCLUSIVE_EXPERIENCES: 'view all exclusive experiences',
    EXCLUSIVE_TOOLTIP: '.exclusive-dashboard-section .custom-info-icon',
    REFER_FRIEND_TOOLTIP: '.refer-dashboard-section .custom-info-icon',
    EXCLUSIVE_OPEN_TOOLTIP: 'open tooltip',
    HOW_TO_EARN_LINK: '.loyalty-how-to-earn-drawer-link',
    VIEW_POINT_ACTIVITY_CTA: '.view-point-activity-cta',
    REWARD_START_SHOPPING: '.reward-start-shopping',
    REWARD_VIEW_CODE_CTA: '.view-code-cta',
    VOUCHER_MODAL_CLOSE_CTA: '.loyalty-reward-in-store-dialog .close',
    OFFER_FLIP_CARD_CTA: '.flip-card-front',
    VIEW_COPY_CODE_CTA: '.view-copy-code-cta',
    OFFER_DETAIL_CLOSE: '.offer-detail-modal .close',
    BONUS_POINTS_DAY: 'Bonus points day',
    EARLY_ACCESS: 'Early Access: New Fall Arrivals',
    LOYALTY_PROGRAM_MODAL: '#loyalty-program-modal .close',
    LOYALTY_ACCOUNT_CARD: '.loyalty-card-dropdown-cta',
    VIEW_LOYALTY_DASHBOARD: '.view-dashboard',
    LOYALTY_DRAWER_LINK: '.loyalty-program-drawer-link',
    LOYALTY_OPT_IN_CHECKBOX: '.add-to-loyalty-program-checkbox',
    ORDER_CONFIRM_REGISTER_MODAL: '.loyalty-order-confirmation-container.show',
    ORDER_CONFIRM_GUEST_ELEMENT: '.order-confirm-loyalty-guest-content',
    CREATE_ACCOUNT_ORDER_CONFIRM: '.create-loyalty-account-btn',
    LOYALTY_NEXT_BUTTON: '.loyalty-welcome-cta',
    CLOSE_WELCOME_MODAL: '.loyalty-welcome-dialog .close-enrollnow-model',
    CLOSE_ENROLL_NOW_MODAL: '.loyalty-enrollnow-dialog .close-enrollnow-model',
    ENROLL_LOYALTY_CTA: '.loyalty-enroll-cta',
    GUEST_LOYALTY_ENROLL: '.guest-create-and-enroll',
    STYLE_QUIZ_START_CTA: '.style-quiz-start-cta',
    STYLE_QUIZ_GO_TO_DASHBOARD: '.style-quiz-go-to-dashboard',
    SWATCH_SELECTION_WRAPPER: '.js-swatch-selection-wrapper .swatch-container',
    NEXT_QUESTION_CTA: '.loyalty-next-question-cta',
    PREVIOUS_QUESTION_CTA: '.loyalty-previous-question-cta',
    SUBMIT_QUIZ_CTA: '.loyalty-submit-quiz-cta',
    SKIP_THIS_QUESTION_CTA: '.loyalty-skip-question-cta',
    FINISH_LATER_CTA: '.loyalty-finish-later-cta',
    START_SHOPPING_CTA: '.style-quiz-start-shopping-cta',
    CONTENT_SLOT_SELECTORS: [
        '.content-module',
        '.promo-asset',
        '.promo-default',
        '.product-recommendations.ways-to-wear',
        '.curalate-content',
        '.product-detail .shop-the-print',
        '.style-component-container',
        '.sfl-empty',
        '.homepage .recommendations .product-listing',
        '.is-recommendation-carousel',
        '.blog-posts-item'
    ],

    // GA Event category, label, action, page name strings
    LOYALTY_BENEFIT_LABEL: 'loyalty benefits',
    LOYALTY_ANNUAL_GIFT_ID: 'annual-member-gift',
    ANNUAL_GIFT_LABEL: 'Annual Member',
    LOYALTY_BIRTHDAY_TREAT_LABEL: 'birthday treat',
    LOYALTY_DIGITAL_WALLPAPER_LABEL: 'digital wallpaper',
    MODAL_CLOSE_LABEL: 'close',
    MODAL_VOUCHER_CLOSE_LABEL: 'close modal',
    MODAL_SELECTED_LABEL: 'select',
    MODAL_DOWNLOAD_LABEL: 'download',
    CLICK_EVENT_ACTION: 'click',
    SHARE_EVENT_ACTION: 'share link',
    LOYALTY_DASHBOARD_LABEL: 'loyalty dashboard',
    LOYALTY_BENEFIT_TOOLTIP_LABEL: 'benefits tooltip',
    BENEFIT_LABEL: 'benefits',
    LOYALTY_EXCLUSIVE_EXPERIENCE_TOOLTIP_LABEL: 'exclusive experiences tooltip',
    LOYALTY_EXCLUSIVE_EXPERIENCE_LABEL: 'exclusive experiences',
    LOYALTY_MEMBERSHIP_FAQ_TOOLTIP_LABEL: 'membership faq tooltip',
    TOOLTIP_LABEL: 'tooltip',
    OPEN_TOOLTIP_LABEL: 'open tooltip',
    CLOSE_TOOLTIP_LABEL: 'close tooltip',
    STYLE_QUIZ_TOOLTIP_LABEL: 'style quiz tooltip',
    STYLE_QUIZ_PAGE_SECTION: 'style quiz',
    LOYALTY_REFER_A_FRIEND_SECTION: 'content or footer',
    LOYALTY_MEMBERSHIP_FAQ_SECTION: 'membership faq',
    LOYALTY_MODAL_PAGE_SUBSECTION: 'web pop up',
    LOYALTY_REFER_A_FRIEND_LABEL_SECTION: 'refer a friend',
    LOYALTY_REFER_A_FRIEND_TOOLTIP: 'refer a friend tooltip',
    LOYALTY_EVENT_LABEL: 'finish your quiz',
    MEMBERSHIP_FAQ_EXPAND_COLLAPSE_ACTION: 'expand question or collapse question',
    MEMBERSHIP_FAQ_EXPAND_COLLAPSE_ALL_ACTION: 'expand all',
    MEMBERSHIP_FAQ_TERMS_AND_CONDITION_LABEL: 'loyalty t&c link',
    REWARDS_TOOLTIP_LABEL: 'rewards tooltip',
    REWARDS_PAGE_SECTION_LABEL: 'rewards',
    EVENT_CLICK_LINK_ACTION: 'click link',
    REWARDS_HOW_TO_EARN_EVENT_LABEL: 'how to earn',
    REWARDS_VIEW_POINT_ACTIVITY_LABEL: 'view points activity',
    REWARDS_START_SHOPPING_LABEL: 'start shopping',
    REWARDS_VIEW_CODE_ACTION: 'click to view',
    REWARDS_VIEW_CODE_LABEL: 'reward vouchers code',
    REWARDS_VOUCHER_MODAL: 'voucher modal',
    REWARDS_VOUCHER_LABEL: 'voucher',
    REWARDS_VOUCHER_PAGE_SECTION: 'modal',
    OFFER_TOOLTIP_LABEL: 'offers tooltip',
    OFFER_PAGE_SECTION: 'offers',
    TAP_TO_REVEAL_ACTION: 'tap to reveal',
    VIEW_AND_OFFER_CODE_CTA: 'view and copy offer code',
    OFFER_CLOSE_EVENT_LABEL: 'offer modal',
    LOYALTY_PROGRAM_LABEL: 'loyalty',
    LOYALTY_PROGRAM_ACTION: 'initiate enrollment',
    LOYALTY_PROGRAM_SUCCESS_ENROLL: 'successful enrollment',
    ENROLL_LOYALTY: 'start enroll',
    LOYALTY_DASHBOARD_ACTION: 'dashboard',
    OPEN_LABEL: 'open',
    MY_ACCOUNT_NAME: 'my account',
    ACCOUNT_PAGE_TYPE: 'account',
    MY_ACCOUNT_TAB: 'account tab',
    LOYALTY_PROGRAM_DETAILS: 'program details',
    LOYALTY_OPT_IN_ACTION: 'opt-in',
    LOYALTY_OPT_IN_CHECKBOX_SELECT_ACTION: 'select',
    LOYALTY_OPT_IN_CHECKBOX_DESELECT_ACTION: 'deselect',
    ACCOUNT_SIGN_IN_CATEGORY: 'account sign in',
    CREATE_AN_ACCOUNT_ACTION: 'create an account',
    SUBMIT_LOYALTY_OPT_IN: 'submit|opt-in loyalty:true',
    SUBMIT_LOYALTY_NOT_OPT_IN: 'submit|opt-in loyalty:false',
    LOYALTY_PROGRAM_WELCOME: 'welcome',
    LOYALTY_PROGRAM_ENROLLMENT: 'enrollment',
    LOYALTY_PROGRAM_ENROLL_SUCCESS: 'enroll:success',
    LOYALTY_STYLE_QUIZ_LABEL: 'loyalty style quiz',
    LOYALTY_STYLE_QUIZ_GET_STARTED_ACTION: 'get started',
    LOYALTY_STYLE_QUIZ_GO_TO_DASHBOARD: 'go to dashboard',
    LOYALTY_STYLE_QUIZ_PAGE_NAME: 'style quiz|cover',
    LOYALTY_STYLE_QUIZ_QUESTION: 'style quiz|q',
    OPTION_SELECTED: 'select',
    OPTION_DESELECTED: 'deselect',
    STYLE_QUIZ_NEXT_CTA: 'next',
    STYLE_QUIZ_PREVIOUS_CTA: 'previous',
    STYLE_QUIZ_SUBMIT_CTA: 'submit',
    STYLE_QUIZ_SKIP_THIS_QUESTION_CTA: 'skip',
    STYLE_QUIZ_FINISH_LATER: 'finish later',
    START_SHOPPING: 'start shopping',
    STYLE_QUIZ_THANK_YOU_PAGE: 'style quiz|thank you',
    PDP_SELECTIONS: 'pdp selections',
    PDP_INTERACTION: 'pdp_interaction',
    CHANGE_STORE: 'change store',
    VIEW_PROMO: 'view_promotion',
    SELECT_PROMO: 'select_promotion',
    VIEW_ITEM_LIST: 'view_item_list',
    VIEW_ITEM: 'view_item',
    SELECT_ITEM: 'select_item',
    SIGN_IN_OR_CREATE_ACCOUNT_ACTION: 'sign in or create account',
    START_LABEL: 'start',
    PDP_PAGE_NAME: 'pdp',
    PRODUCT_PAGE_TYPE: 'product',
    CREATE_ACCOUNT_ACTION: 'create account',
    SUBMIT_EVENT_LABEL: 'submit or success or failure',
    JOIN_CLUB_LILLY_EVENT: 'join club lilly',
    INITIATE_ACTION: 'initiate',
    LOYALTY_ENROLLMENT_CATEGORY: 'loyalty enrollment',
    ACCOUNT_DASHBOARD_PAGE: 'account dashboard',
    GET_DIRECTIONS: 'get directions',
    STORE_INTERACTION: 'store_interaction',
    ADD_TO_CART: 'add_to_cart',

    LOYALTY_VIEW_VOUCHER_CODE_LABEL: 'view voucher code',
    REWARDS_POINT_ACTIVITY_LABEL: 'points activity',
    REWARDS_SHOP_TAB_LABEL: 'shop tab',

    SHARED_WISHLIST_CREATE_ACCOUNT_CATEGORY: 'account sign in',
    SHARED_WISHLIST_CREATE_ACCOUNT_ACTION: 'sign in or create account',
    SHARED_WISHLIST_CREATE_ACCOUNT_LABEL: 'start',
    SHARED_WISHLIST_PAGE_SUBSECTION: 'wishlist',
    SHARED_WISHLIST_NON_LOYALTY_CATEGORY: 'loyalty enrollment',
    SHARED_WISHLIST_NON_LOYALTY_ACTION: 'initiate',
    SHARED_WISHLIST_NON_LOYALTY_LABEL: 'join club lilly',
    LOYALTY_ENROLL_MODAL_INTERACTION_TYPE: 'loyalty enroll modal',
    SIGN_IN_OR_SIGN_UP_ENROLL_MODAL: 'sign in/up to enroll modal',
    LOYALTY_ENROLLMENT_EVENT_NAME: 'loyalty_enrollment',
    LOYALTY_VOUCHERS_EVENT_NAME: 'loyalty_dashboard_vouchers',
    BOLT_INTERACTION: 'bolt_interaction',
    LOYALTY_DASHBOARD_REWARDS_EVENT_NAME: 'loyalty_dashboard_rewards',
    BOLT_MODAL_PROCEED: 'otp:proceed',
    BOLT_MODAL_CLOSE: 'otp:close',
    LOYALTY_REWARDS_EVENT_NAME: 'loyalty_rewards',
    BOLT_SIGN_OUT: 'otp:sign out',
    NEWSLETTER_SIGNUP_COMPLETE: 'newsletter_signup_complete',
    NEWSLETTER_SIGNUP_FAILURE: 'newsletter_signup_failure',
    P_PRODUCT_LIST_KEY: 'product_list',
    P_PROMO_NAME_KEY: 'promo_name'
};
